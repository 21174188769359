import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useAuth } from "@/containers/authData"
import { paths } from "@/paths"
import Link from "next/link"
import { useRouter } from "next/router"
import styled from "styled-components"

import { Form } from "./Form"
import { FormInput } from "./Form/FormInput"
import { Text2 } from "./Text2"
import { Button, Flex, GeneralError } from "."

type LoginFormFields = {
    email: string
    password: string
}

const FormWrapper = styled.div`
    display: flex;
    align-content: center;
    justify-content: center;
    gap: 75px;
    max-width: 348px;
    width: 100%;
    flex-direction: column;
`

export const LoginForm = () => {
    const router = useRouter()
    const { login, userData } = useAuth()

    useEffect(() => {
        if (userData) {
            router.replace(paths.hrTests.go())
        }
    }, [router, userData])

    const { t } = useTranslation("auth")
    const [showGeneralError, setShowGeneralError] = useState(false)

    const {
        register,
        handleSubmit,
        setError,
        formState: { errors, isValid, isSubmitted, dirtyFields, isSubmitting },
    } = useForm<LoginFormFields>({ mode: "onSubmit" })

    const onSubmit = async ({ email, password }: LoginFormFields) => {
        try {
            await login(email, password)
        } catch (e) {
            if (e.message.includes("Authorization failed")) {
                setError("password", { message: t("authError") })
            } else {
                setShowGeneralError(true)
            }
        }
    }

    return (
        <FormWrapper>
            <Flex col gap="4px">
                <Text2 $scale font="h1">
                    {t("loginWelcomeText")}
                </Text2>
                <Text2 $scale font="h3">
                    {t("loginWelcomeSubText")}
                </Text2>
            </Flex>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <FormInput
                    labelText={t("emailLabel")}
                    inputName={"email"}
                    type="email"
                    placeholder={t("generalPlaceholder")}
                    dirtyFields={dirtyFields}
                    errors={errors}
                    colorPattern="violet"
                    isDisabled={isSubmitting}
                    showSuccessValidation={false}
                    inputRef={register("email", {
                        pattern: {
                            value: /^[A-Z0-9._&%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: t("emailValid"),
                        },
                        required: t("inputRequired") as string,
                    })}
                />

                <FormInput
                    labelText={t("passwordLabel")}
                    inputName={"password"}
                    placeholder={t("generalPlaceholder")}
                    dirtyFields={dirtyFields}
                    errors={errors}
                    type="password"
                    colorPattern="violet"
                    isDisabled={isSubmitting}
                    showSuccessValidation={false}
                    inputRef={register("password", {
                        required: t("inputRequired") as string,
                    })}
                />
                <Flex $wrap gap="20px" style={{ marginTop: "42px" }} justify="space-between" align="center">
                    <Button
                        loading={isSubmitting}
                        loadingText={t("loading")}
                        disabled={isSubmitted && !isValid}
                        type="submit"
                        label={t("loginButton")}></Button>
                    <Link passHref href={paths.reset_password.go()} legacyBehavior>
                        <a>
                            <Text2 font="t2" color="blue500">
                                {t("forgotPassword")}
                            </Text2>
                        </a>
                    </Link>
                </Flex>
                {showGeneralError && <GeneralError>{t("generalError")}</GeneralError>}
            </Form>
        </FormWrapper>
    )
}
