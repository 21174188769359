import React from "react"
import { CustomHelmet } from "@/components/common"
import { LoginForm } from "@/components/common/LoginForm"
import { OnboardingLayout } from "@/components/ui/Layout/OnboardingLayout/OnboardingLayout"
import { styled } from "@/components/ui/theme"
import i18n from "@/i18n"
import authEn from "@/locales/en/auth.json"
import onboardingLayoutEn from "@/locales/en/onboardingLayout.json"
import authPl from "@/locales/pl/auth.json"
import onboardingLayoutPl from "@/locales/pl/onboardingLayout.json"
import { AppProvider } from "@/providers/NewAppProvider"
import { LegacyThemeProvider } from "@/styles/ThemeProvider"
import { NextPageWithLayout } from "@/utils/next"

i18n.addResources("pl", "auth", authPl)
i18n.addResources("en", "auth", authEn)

i18n.addResources("pl", "onboardingLayout", onboardingLayoutPl)
i18n.addResources("en", "onboardingLayout", onboardingLayoutEn)

const Login: NextPageWithLayout = () => {
    return (
        <StyledOnboardingLayout>
            <LegacyThemeProvider>
                <CustomHelmet title="login" />
                <LoginForm />
            </LegacyThemeProvider>
        </StyledOnboardingLayout>
    )
}

const StyledOnboardingLayout = styled(OnboardingLayout)`
    max-height: 600px;
`

Login.getLayout = props => <AppProvider {...props} />

export default Login
